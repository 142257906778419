uniform vec3 uColor;

void main() 
{
    float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
    float strength = 0.05 / distanceToCenter - 0.1;

    // Final Color

    /* //gaht nöd!!!!!
    vec3 color = uColor; */



    gl_FragColor = vec4(uColor, strength);
}