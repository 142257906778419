// Pack
import GUI from 'lil-gui'
import * as THREE from 'three'
// Font & Fontloader
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'  
// Controls
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// Loader
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
// DRACO
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
// Fire Flies Shader
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'
// Portal Shaders
import portalVertexShader from './shaders/portal/vertex.glsl'
import portalFragmentShader from './shaders/portal/fragment.glsl'
import { VRButton } from 'three/addons/webxr/VRButton.js';
// Physics
// import * as CANNON from 'cannon-es'


/**
 * Base
 */
// Debug
const debugObject = {}
const gui = new GUI({
    width: 400,
    title: 'Debug UI',
    closeFolders: true,
    closed: true
})


/**
 * Folders for Light Color in Debug UI
 */
const bgColor = gui.addFolder('Background Color')
const portalLightColor = gui.addFolder('Portal Light Color')
const fireFlies = gui.addFolder('Fire Flies')
const directionalLightRight = gui.addFolder('Direct Light Right')
const directionalLightLeft = gui.addFolder('Direct Light Left')
const directionalLightFront = gui.addFolder('Direct Light Front')
const directionalLightBack = gui.addFolder('Direct Light Back')


/**
 * Press (h) to open or clode the Debug UI
 */
window.addEventListener('keydown', (event) => {
    if(event.key == 'h')
    gui.show(gui._hidden)
}) 


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

const gltfLoader1 = new GLTFLoader()
gltfLoader1.setDRACOLoader(dracoLoader)

/**
 * Textures
 */
const bakedTexture = textureLoader.load('baked-new-scene-asp.jpg')
const matcapTexture = textureLoader.load('/textures/matcaps/8.png')

matcapTexture.colorSpace = THREE.SRGBColorSpace

/**
 * Fonts
 */

const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font)=> {
       // console.log('font loaded')

       const textGeometry = new TextGeometry(
        "Foxy's Shelter Lodge",
        {
            font: font,
            size: 0.3,
            height: 0.1,
            curveSegments: 5,
            bevelEnabled: true,
            bevelThickness: 0.06,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 5,
        }
       )

       textGeometry.center()

       const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
       const text = new THREE.Mesh(textGeometry, material)
       text.position.x = 0
       text.position.y = 1.5
       text.position.z = -2
       scene.add(text)
       }
)

/**
 * Materials
 */

// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })
bakedTexture.flipY = false
bakedTexture.colorSpace = THREE.SRGBColorSpace

// Pole light material
const poleLightMaterial = new THREE.MeshBasicMaterial({ color: 0xffffe5 })

// Portal light material
// Add the Color Change to GUI
debugObject.portalColorStart = '#00fbff'
debugObject.portalColorEnd = '#0a0a0b'

portalLightColor
    .addColor(debugObject, 'portalColorStart')
    .onChange(() =>
    {
        portalLightMaterial.uniforms.uColorStart.value.set(debugObject.portalColorStart)
    })
    
portalLightColor
    .addColor(debugObject, 'portalColorEnd')
    .onChange(() =>
    {
        portalLightMaterial.uniforms.uColorEnd.value.set(debugObject.portalColorEnd)
    })
    


const portalLightMaterial = new THREE.ShaderMaterial({ 
    uniforms: 
    {
        uTime: { value: 0 },
        uColorStart: { value: new THREE.Color(debugObject.portalColorStart)},
        uColorEnd: { value: new THREE.Color(debugObject.portalColorEnd)}
    },

    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,

})



/**
 * Fireflies
 */

// Geometry
const fireFliesGeometry = new THREE.BufferGeometry()

/* Count of Fireflies */
const fireFliesCount = 100

/* Positioning the FF */
const positionArray = new Float32Array(fireFliesCount * 3)

// Scale
const scaleArray = new Float32Array(fireFliesCount)

for(let i = 0; i < fireFliesCount; i++) {

    positionArray[i * 3 + 0] = (Math.random() -0.5) * 4
    // gett it Higher increase the value
    positionArray[i * 3 + 1] = Math.random() * 1.5
    positionArray[i * 3 + 2] = (Math.random() -0.5) * 4

    // scale
    scaleArray[i] = Math.random()

}

// Converting to a Buffer Attribute
fireFliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
fireFliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

/**
 * Material for the Fireflies
 */

// Portal light material
// Add the Color Change to GUI
debugObject.fireFliesColor = '#ffffff'

fireFlies
    .addColor(debugObject, 'fireFliesColor')
    .onChange(() =>
    {
        fireFliesMaterial.uniforms.uColor.value.set(debugObject.fireFliesColor)
    })

// Material
const fireFliesMaterial = new THREE.ShaderMaterial({
    uniforms: 
    {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 100 },
        uColor: { value: new THREE.Color(debugObject.fireFliesColor) }

    },
    vertexShader: firefliesVertexShader,
    fragmentShader: firefliesFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false
})

fireFlies.add(fireFliesMaterial.uniforms.uSize, 'value').min(0).max(500).step(1).name('Fireflies Size')

// Points
const fireflies = new THREE.Points(fireFliesGeometry, fireFliesMaterial)
scene.add(fireflies)


/**
 * Model
 */

let foxMixer = null

gltfLoader.load(
    '/models/Fox/glTF/Fox.gltf',
    (gltf1) => {
        const model1 = gltf1.scene;

        //Fox model 

        // Animate
        foxMixer = new THREE.AnimationMixer(gltf1.scene);
        const action = foxMixer.clipAction(gltf1.animations[0]); // Change Animation if needed

        action.play();

        model1.scale.set(0.004, 0.004, 0.004); // Change Scale
        
        model1.position.x = 0.5
        model1.position.y = 0
        model1.position.z = 0
        
        scene.add(model1);

    },
    () => {
        console.log('progress');
    },
    (error) => {
        console.log('error', error);
    }
);

let model = null

gltfLoader.load(
    'new-scene-asp.glb',
    (gltf2) =>
    {
        gltf2.scene.traverse((child) =>
        {
            child.material = bakedMaterial
        })
        model = gltf2.scene
        scene.add(gltf2.scene)

        // Get each object
        const portalLightMesh = gltf2.scene.children.find((child) => child.name === 'portalLight')
        const poleLightAMesh = gltf2.scene.children.find((child) => child.name === 'poleLightA')
        const poleLightBMesh = gltf2.scene.children.find((child) => child.name === 'poleLightB')

        // Apply materials
        portalLightMesh.material = portalLightMaterial
        poleLightAMesh.material = poleLightMaterial
        poleLightBMesh.material = poleLightMaterial
    },
    
)


/**
 * lights
 */


const directionalLight = new THREE.DirectionalLight(0xffffff,1.9)
directionalLight.position.set(4, 0.8, 0);
const directionalLight2 = new THREE.DirectionalLight(0xffffff,1.9)
directionalLight2.position.set(0, 0.5, 3)
const directionalLight3 = new THREE.DirectionalLight(0xffffff,0.9)
directionalLight3.position.set(-4, 0.8, 0)
const directionalLight4 = new THREE.DirectionalLight(0xffffff,5.9)
directionalLight4.position.set(0, 0.8, -3)
scene.add(directionalLight, directionalLight2, directionalLight3, directionalLight4)
/* const helper = new THREE.DirectionalLightHelper(directionalLight, 1)
const helper2 = new THREE.DirectionalLightHelper(directionalLight2, 1)
const helper3 = new THREE.DirectionalLightHelper(directionalLight3, 1)
const helper4 = new THREE.DirectionalLightHelper(directionalLight4, 1)
scene.add(helper, helper2, helper3, helper4); */

// GUI ADD Light
directionalLightRight.add(directionalLight, 'intensity').min(0).max(3).step(0.001)
directionalLightLeft.add(directionalLight3, 'intensity').min(0).max(3).step(0.001)
directionalLightFront.add(directionalLight2, 'intensity').min(0).max(3).step(0.001)
directionalLightBack.add(directionalLight4, 'intensity').min(0).max(6).step(0.001)



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update FireFlies
    fireFliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 4
camera.position.y = 2
camera.position.z = 4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.xr.enabled = true;

debugObject.clearColor = '#050505'
// link to Background
renderer.setClearColor(debugObject.clearColor)

// Update the Color
bgColor
    .addColor(debugObject,'clearColor')
    .onChange(() => {
        renderer.setClearColor(debugObject.clearColor)
    })

    document.body.appendChild( VRButton.createButton( renderer ) );


/**
 * Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    

    // Update Materials
    portalLightMaterial.uniforms.uTime.value = elapsedTime
    fireFliesMaterial.uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    if(foxMixer !== null){
        foxMixer.update(deltaTime)
    }

    // Render
    renderer.render(scene, camera)


    // Call tick again on the next frame
    renderer.setAnimationLoop(tick)
}

tick()